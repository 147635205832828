import React, { useContext } from "react";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
// import { translateStatic } from "../../../helpers/translationHelper";
import { templateVariableValue } from "../../../helpers/templateVariables";
import * as theme3Styles from "../scss/theme3.module.scss";

import default_logo from "../images/logo.png";

function Footer() {
  const siteData = useContext(SiteMetaContext);
  const websiteName = siteData.remarso.domainByURL.website.name
    ? siteData.remarso.domainByURL.website.name
    : siteData.site.siteMetaData.title;
  const {
    remarso: {
      domainByURL: {
        website: {
          logo,
          templateConfiguration,
          template: { templateVariables },
          company: {
            address,
            city,
            country: { country_name },
            logo: company_logo,
            state,
            postal_code,
            email: company_email,
            website: company_website,
            phone: company_phone,
            socialProfiles,
          },
          contact_detail_user: user,
          setting: display_settings,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const {
    display_company_logo,
    display_contact_address,
    display_contact_phone,
    display_contact_website,
    display_contact_email,
  } = display_settings;

  const footerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-footer",
    locale,
    defaultLocale
  );
  const showLogo = footerLogo || logo || company_logo || default_logo;

  const footerText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "about-footer",
    locale,
    defaultLocale
  );

  const footerImage = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "footer-image",
    locale,
    defaultLocale
  );

  const contact_email = user ? user.email : company_email;
  const contact_phone = user
    ? user.countryCode + user.mobile_phone
    : company_phone;
  const social_profiles = user ? user.socialProfiles : socialProfiles;

  return (
    <footer className={theme3Styles.mainFooter}>
      <div className={theme3Styles.wrapper}>
        <div className={theme3Styles.footerTxt}>
          <div className={theme3Styles.wrap}>{footerText}</div>
        </div>
        <ul className={theme3Styles.footerInfos}>
          {(() => {
            if (display_company_logo) {
              return (
                <li>
                  <img alt="logo" src={showLogo} />
                </li>
              );
            }
          })()}

          {social_profiles.length > 0 && (
            <li>
              <div className={theme3Styles.followUs}>
                <span>Follow us:</span>
                {social_profiles.map(({ type, profile }) => {
                  return (
                    <a
                      href={profile}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={type}
                    >
                      <img
                        alt={type}
                        src={require(`../images/social/${type}.png`).default}
                      />
                    </a>
                  );
                })}
              </div>
            </li>
          )}

          <li>&copy; 2020 Remarso</li>
        </ul>
        <div className={theme3Styles.poweredBy}>
          Powered by{" "}
          <a href="https://realestatecrm.io/" target="_blank" rel="noreferrer">
            RealEstateCRM.io
          </a>
        </div>
      </div>
      {footerImage && (
        <div
          className={theme3Styles.footerImage}
          style={{ backgroundImage: `url(${footerImage})` }}
        >
          &nbsp;
        </div>
      )}
      {!footerImage && <div className={theme3Styles.footerImage}>&nbsp;</div>}
      
    </footer>
  );
}

export default Footer;
