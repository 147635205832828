import React, { Component } from "react";

import ContactFormComponent1 from "../themes/theme1/components/ContactFormComponent1";
import ContactFormComponent2 from "../themes/theme2/components/ContactFormComponent2";
import ContactFormComponent3 from "../themes/theme3/components/ContactFormComponent3";
import ContactFormComponent4 from "../themes/theme4/components/ContactFormComponent4";
import ContactFormComponent5 from "../themes/theme5/components/ContactFormComponent5";

import SiteMetaContext from "../contexts/SiteMetaContext";
import trackip from "./trackip";
import "react-intl-tel-input/dist/main.css";

const validEmailRegex = RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class ContactFormContainer extends Component {
  static contextType = SiteMetaContext;

  constructor() {
    super();
    this.state = {
      weblisting_id: 0,
      locationDetails: {},
      api_success: "",
      api_error: "",
      api_key: "",
      owned_by: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      errors: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateFormValues = this.validateFormValues.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.prepareFormData = this.prepareFormData.bind(this);
  }
  componentDidMount() {
    // console.log(this.context);

    const {
      remarso: {
        domainByURL: {
          website: { id: weblisting_id },
        },
      },
    } = this.context;

    this.setState({ weblisting_id });

    this.setAssignedTo();
  }

  setAssignedTo() {
    const {
      remarso: {
        domainByURL: {
          website: {
            assigned_to_user,
            assigned_to_group,
          },
        },
      },
    } = this.context;

    let assigned_user = assigned_to_user || assigned_to_group?.leader || null;
    let owned_by_type = assigned_to_user ? 'user' : (assigned_to_group ? 'group' : null);
    let owned_by_id = assigned_to_user?.id || assigned_to_group?.id || null;
    let owned_by = owned_by_type + '-' + owned_by_id;
    let { api_key } = assigned_user || {};

    this.setState({ api_key, owned_by });
  }

  /*setAssignedTo() {
    const {
      remarso: {
        domainByURL: {
          website: {
            webform_assign_type,
            webform_assigned_to_user,
            webform_assigned_to_group,
          },
        },
      },
      building,
      property,
    } = this.context;

    let assigned_user = null;
    let owned_by_type = null;
    let owned_by_id = null;
    let owned_by = null;

    const listing = building || property || null;
    if (webform_assign_type == "listing_assigned_to" && listing) {
      const { assigned_to_user, assigned_to_group } = listing;
      assigned_user = assigned_to_user || assigned_to_group?.leader || null;
      owned_by_type = assigned_to_user
        ? "user"
        : assigned_to_group
        ? "group"
        : null;
      owned_by_id = assigned_to_user?.id || assigned_to_group?.id || null;
      owned_by =
        owned_by_type && owned_by_id ? owned_by_type + "-" + owned_by_id : null;
    } else if (webform_assign_type == "listing_assigned_agent" && listing) {
      assigned_user = listing.agent;
      if (assigned_user) {
        owned_by = "user-" + assigned_user.id;
      }
    } else if (
      webform_assign_type != "listing_assigned_to" &&
      webform_assign_type != "listing_assigned_agent"
    ) {
      assigned_user =
        webform_assigned_to_user || webform_assigned_to_group?.leader || null;
      owned_by_type = webform_assigned_to_user
        ? "user"
        : webform_assigned_to_group
        ? "group"
        : null;
      owned_by_id =
        webform_assigned_to_user?.id || webform_assigned_to_group?.id || null;
      owned_by =
        owned_by_type && owned_by_id ? owned_by_type + "-" + owned_by_id : null;
    }

    const { api_key } = assigned_user || {};

    this.setState({ api_key, owned_by });
  }*/

  handleChange(event) {
    event.preventDefault();

    const { name, value } = event.target;
    let errors = this.validateFormValues(name, value);

    this.setState({ errors, [name]: value });
  }

  handlePhoneChange(isValid, number, country) {
    // console.log('test', isValid, this.state.errors)
    let errors = this.state.errors;
    if (isValid) {
      errors.phoneNumber = "";
    } else {
      errors.phoneNumber = "Phone Number is not valid";
    }
    this.setState({ errors, phoneNumber: country.dialCode + "-" + number });
    // if (isValid) {
    //     this.setState({ errors: { phoneNumber: '' } });
    // }
    // this.setState({ errors: { phoneNumber: 'Phone Number is not valid' } });
  }

  validateFormValues(name, value) {
    let errors = this.state.errors;
    switch (name) {
      case "firstName":
        errors.firstName = value.length <= 0 ? "First Name is required!" : "";
        break;
      case "lastName":
        errors.lastName = value.length <= 0 ? "Last Name is required!" : "";
        break;
      case "emailAddress":
        errors.emailAddress = validEmailRegex.test(value)
          ? ""
          : "Email is not valid!";
        break;
      case "phoneNumber":
        errors.phoneNumber =
          value.length <= 0 ? "Phone Number is required!" : "";
        break;
      default:
        break;
    }
    return errors;
  }

  serializeForm(form) {
    /**
     * The JSON placeholder doesn’t accept FormData.It wants a JSON object.
     **/
    let obj = {};
    let formData = new FormData(form);
    for (let key of formData.keys()) {
      obj[key] = formData.get(key);
    }
    return obj;
  }
  prepareFormData(form, api_key, owned_by) {
    let obj = {};
    let formData = new FormData(form);
    for (let key of formData.keys()) {
      if (key === "firstName") {
        obj["first_name"] = formData.get(key);
      }
      if (key === "lastName") {
        obj["last_name"] = formData.get(key);
      }
      if (key === "emailAddress") {
        obj["email"] = formData.get(key);
      }
      if (key === "phoneNumber") {
        obj["phone"] = this.state.phoneNumber;
      }
    }
    obj["api_key"] = api_key;
    obj["owned_by"] = owned_by;
    obj["lead_source_id"] = 8;
    trackip().then((ipDetails) => {
      obj["addr_city"] = ipDetails.city;
      obj["addr_country_id"] = ipDetails.country;
    });

    const { location } = this.context;
    obj["lead_source_description"] = location.href;

    /**
     * Added only for the listing details page
     */
    const { building, property } = this.context;
    if (building) {
      obj["listing_type"] = "building";
      obj["listing_id"] = building.id;
      obj["name"] = building.name;
    } else if (property) {
      obj["listing_type"] = "property";
      obj["listing_id"] = property.id;
      obj["name"] = property.reference;
      obj["annual_revenue"] = property.selling_price || property.rental_price;
    }

    obj["create_new_contact"] = 1;
    obj["weblisting_id"] = this.state.weblisting_id;

    return obj;
  }

  handleSubmit(event) {
    event.preventDefault();
    const formValues = this.serializeForm(event.target);
    for (let key in formValues) {
      let errors = this.validateFormValues(key, formValues[key]);
      this.setState({ errors, [key]: formValues[key] });
    }

    if (validateForm(this.state.errors)) {
      // console.info("Valid Form");
      const prepareFormData = this.prepareFormData(
        event.target,
        this.state.api_key,
        this.state.owned_by
      );
      // console.log(prepareFormData);
      const url = process.env.GATSBY_API_ENDPOINT + "webform/leads/create";
      fetch(url, {
        method: "POST",
        // body: new FormData(event.target),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prepareFormData),
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.status) {
            if (typeof window !== "undefined" && window._paq) {
              const userId = data.data[0].uuid;
              document.cookie = `userId=${userId}`;
              window._paq.push(["setUserId", userId]);
              window._paq.push([
                "trackEvent",
                "submit",
                "Lead submission",
                document.domain + "/" + document.title,
              ]);
            }
            this.setState({ api_error: "" });
            this.setState({ api_success: "Thanks for submitting your query." });
          } else {
            this.setState({ api_success: "" });
            this.setState({ api_error: data.message.message });
          }
        })
        .catch((error) => {
          console.warn(error);
          this.setState({ api_success: "" });
          this.setState({
            api_error: "We are unable to process now. Please try later.",
          });
        });
    } else {
      console.error("Invalid Form");
    }
  }

  render() {
    // console.log(this.context);
    const { currentLocaleKey, defaultLocaleKey } = this.context;
    const { template } = this.context.remarso.domainByURL.website;
    /** Begin: Theme Switcher */
    const templateId =
      process.env.GATSBY_TEST_THEME === "0"
        ? template.id
        : process.env.GATSBY_TEST_THEME;
    // console.log(typeof process.env.GATSBY_TEST_THEME, templateId, template.id);
    const themes = [
      ContactFormComponent1,
      ContactFormComponent2,
      ContactFormComponent3,
      ContactFormComponent4,
      ContactFormComponent5,
    ];
    const ContactFormComponent =
      parseInt(templateId) > 0
        ? themes[parseInt(templateId) - 1]
        : ContactFormComponent1;
    /** End: Theme Switcher */
    return (
      <ContactFormComponent
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        data={this.state}
        handlePhoneChange={this.handlePhoneChange}
        locale={currentLocaleKey}
        defaultLocale={defaultLocaleKey}
      />
    );
  }
}

export default ContactFormContainer;
