/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";

import SiteMetaContext from "../contexts/SiteMetaContext";
import { getCurrentLocale } from "../helpers/translationHelper";
import { readCookie } from "../helpers/helper";

function WithThemeLoading(Component) {
  const result = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      remarso {
        domainByURL {
          id
          subdomain
          domain
          status
          website {
            id
            name
            theme
            logo
            template {
              id
              templateVariables {
                name
                slug
                type
                logo
                default_value
              }
            }
            setting {
              display_company_logo
              display_menu
              display_contact_form
              display_footer
              display_search_bar
              display_watermark
              display_listings
              display_listing_contact_form
              display_listing_location_map
              display_listing_assigned_to_detail
              display_projects
              display_project_layouts
              display_project_location_map
              display_project_listings
              display_project_assigned_to_detail
              display_company_name
              display_contact_name
              display_contact_address
              display_contact_phone
              display_contact_website
              display_contact_email
            }
            templateConfiguration {
              name
              value
              locale
              templateVariable {
                name
                slug
                type
                logo
                default_value
              }
            }
            menus(visibility: true, whereNull: "parent_id") {
              id
              translations {
                name
                link
                locale
              }
              children {
                id
                translations {
                  name
                  link
                  locale
                }
              }
            }
            company {
              id
              name
              address
              city
              state
              postal_code
              email
              phone
              website
              logo
              country {
                country_name
              }
              languages {
                id
                name
                language_key
              }
              socialProfiles {
                type
                profile
              }
              currency {
                id
                name
                symbol
                code
              }
              googleMapApiKey
              matomoTrackingScript
            }
            languages {
              id
              name
              language_key
            }
            default_language {
              id
              name
              language_key
            }
            assigned_to_user {
              id
              name
              email
              mobile_phone
              countryCode(key: "mobile_phone_code")
              api_key
              profile_image
              socialProfiles {
                type
                profile
              }
            }
            assigned_to_group {
              id
              name
              leader {
                id
                name
                email
                mobile_phone
                countryCode(key: "mobile_phone_code")
                api_key
                profile_image
                socialProfiles {
                  type
                  profile
                }
              }
            }
            webform_assign_type
            webform_assigned_to_user {
              id
              name
              email
              mobile_phone
              countryCode(key: "mobile_phone_code")
              api_key
              profile_image
              socialProfiles {
                type
                profile
              }
            }
            webform_assigned_to_group {
              id
              name
              leader {
                id
                name
                email
                mobile_phone
                countryCode(key: "mobile_phone_code")
                api_key
                profile_image
                socialProfiles {
                  type
                  profile
                }
              }
            }
            contact_detail_user {
              id
              name
              email
              mobile_phone
              countryCode(key: "mobile_phone_code")
              profile_image
              socialProfiles {
                type
                profile
              }
            }
            list {
              id
              list_name
              listing_type              
            }
          }
        }
      }
    }
  `);

  const {
    remarso: {
      domainByURL: {
        website: {
          company: { languages: company_languages },
          languages: weblisting_languages,
          default_language,
        },
      },
    },
  } = result;

  let WithThemeComponent = ({ ...props }) => {
    let locales = [];
    if (weblisting_languages.length > 0) {
      locales = weblisting_languages;
    } else if (company_languages.length > 0) {
      locales = company_languages;
    } else {
      locales = [{ id: 1, name: "English", language_key: "en" }];
    }

    let defaultLocaleKey = default_language
      ? default_language.language_key
      : null;

    const { location } = props;
    const currentLocaleKey =
      getCurrentLocale(location.pathname) ||
      defaultLocaleKey ||
      locales[0].language_key;
    const currentLocale = _.find(locales, { language_key: currentLocaleKey });

    const data = {
      ...result,
      ...{
        location,
        locales,
        currentLocaleKey,
        currentLocale,
        defaultLocaleKey: defaultLocaleKey || "en",
        property: props.pageContext?.property,
        building: props.pageContext?.building,
      },
    };

    useEffect(() => {
      setTimeout(() => {
        if (typeof window !== "undefined") {
          if (window.PAGE_LOADED && window._paq) {
            window._paq.push([
              "setDocumentTitle",
              window.document.domain + "/" + window.document.title,
            ]);
            window._paq.push(["setCustomUrl", window.location.href]);
            window._paq.push(["trackPageView"]);
          } else {
            window.PAGE_LOADED = true;
            const userId = readCookie("userId");
            if (userId && typeof window !== "undefined" && window._paq) {
              window._paq.push(["setUserId", userId]);
            }
          }
        }
      }, 500);
    }, []);

    return (
      <SiteMetaContext.Provider value={data}>
        <Component {...props} result={data} />
      </SiteMetaContext.Provider>
    );
  };

  WithThemeComponent.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return WithThemeComponent;
}

export default WithThemeLoading;
