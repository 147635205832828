export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const prepareImageURL = (
  picture,
  width,
  height,
  is_watermark_enabled = false
) => {
  if (!picture) {
    return null;
  }

  if (is_watermark_enabled) {
    return `${picture.watermark_url}?h=${height}&w=${width}&fit=crop`;
  }

  return `${picture.image_url}?h=${height}&w=${width}&fit=crop`;
};

export const formatLink = (link) => {
  if (!link.startsWith("https://") && !link.startsWith("http://")) {
    link = link[0] == "/" ? link : "/" + link;
  }

  return link;
};

export const compareObjects = (o1, o2) => {
  for (var i in o1) {
    if (o2.hasOwnProperty(i)) {
      if (o1[i] != o2[i]) {
        return false
      }
    } else {
      return false;
    }
  }

  for (var i in o2) {
    if (o1.hasOwnProperty(i)) {
      if (o1[i] != o2[i]) {
        return false
      }
    } else {
      return false;
    }
  }

  return true;
}
