import React, { useState, useContext } from "react";
import { Link } from "gatsby";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { templateVariableValue } from "../../../helpers/templateVariables";
import {
  getTranslatedValue,
  getTranslatedLink,
  translateStatic,
} from "../../../helpers/translationHelper";
import { formatLink } from "../../../helpers/helper";
import ContactFormContainer from "../../../common/ContactFormContainer";
import * as theme3Styles from "../scss/theme3.module.scss";

import default_logo from "../images/logo.png";
import profilePic from "../images/profile-pic.jpg";
import dnarrow from "../images/down-arrow-black.svg";
import close from "../images/close.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isShowLang, toggleLang] = useState(false);
  const [isShowModal, toggleModal] = useState(false);
  const [isSubMenu, toggleSubMenu] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);
  const siteData = useContext(SiteMetaContext);

  const handleSubMenu = (menu_id) => {
    if (menu_id == menuIndex) {
      toggleSubMenu(!isSubMenu);
    } else {
      toggleSubMenu(true);
      setMenuIndex(menu_id);
    }
  };

  const {
    remarso: {
      domainByURL: {
        website: {
          company: { logo: company_logo },
          logo,
          menus,
          setting: display_settings,
          webform_assigned_to_user,
          webform_assigned_to_group,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    location,
    locales,
    currentLocale,
    currentLocaleKey,
    defaultLocaleKey,
  } = siteData;

  const {
    display_company_logo,
    display_contact_form,
    display_menu,
  } = display_settings;

  const headerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-header",
    currentLocaleKey,
    defaultLocaleKey
  );
  const showLogo = headerLogo || logo || company_logo || default_logo;

  const contactLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "contact-logo",
    currentLocaleKey,
    defaultLocaleKey
  );

  const assigned_user =
    webform_assigned_to_user || webform_assigned_to_group?.leader;

  return (
    <header className={theme3Styles.mainHeader}>
      <div className={theme3Styles.wrapper}>
        <div className={theme3Styles.headerArea}>
          <div className={theme3Styles.headerLeft}>
            <button
              className={`${theme3Styles.mobileNavBar} flex items-center block px-3 py-2 ml-3 md:ml-6 text-white border border-white rounded md:hidden`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className={`w-3 h-3 fill-current`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <div
              className={`${isExpanded ? `${theme3Styles.showNow}` : ` `} ${
                theme3Styles.navOverlay
              }`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              &nbsp;
            </div>
            <nav
              className={`${theme3Styles.navbar} ${
                isExpanded ? `${theme3Styles.showNow}` : ` `
              } `}
            >
              <div className={theme3Styles.navBx}>
                <ul>
                  {(() => {
                    if (display_menu) {
                      return menus.map((menu) => {
                        if (menu.children.length > 0) {
                          return (
                            <li
                              className={`${theme3Styles.subNav}`}
                              key={menu.id}
                            >
                              <a>
                                {getTranslatedValue(
                                  menu.translations,
                                  "name",
                                  currentLocaleKey,
                                  defaultLocaleKey
                                )}
                              </a>
                              <span
                                onClick={() => handleSubMenu(menu.id)}
                                className={`${
                                  isSubMenu && menuIndex == menu.id
                                    ? `${theme3Styles.open}`
                                    : ` `
                                } ${theme3Styles.subClick}`}
                              ></span>
                              <ul
                                className={`${
                                  isSubMenu && menuIndex == menu.id
                                    ? `${theme3Styles.open}`
                                    : ` `
                                }`}
                              >
                                {menu.children.map((child) => {
                                  let link = getTranslatedValue(
                                    child.translations,
                                    "link",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  );
                                  if (link) {
                                    return (
                                      <li key={child.id}>
                                        <Link to={formatLink(link)}>
                                          {getTranslatedValue(
                                            child.translations,
                                            "name",
                                            currentLocaleKey,
                                            defaultLocaleKey
                                          )}
                                        </Link>
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </li>
                          );
                        } else {
                          let link = getTranslatedValue(
                            menu.translations,
                            "link",
                            currentLocaleKey,
                            defaultLocaleKey
                          );
                          if (link) {
                            return (
                              <li key={menu.id}>
                                <Link to={formatLink(link)}>
                                  {getTranslatedValue(
                                    menu.translations,
                                    "name",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  )}
                                </Link>
                              </li>
                            );
                          }
                        }
                      });
                    }
                  })()}

                  {(() => {
                    if (display_contact_form) {
                      return (
                        <li>
                          <a
                            onClick={() => (
                              toggleModal(!isShowModal),
                              toggleExpansion(!isExpanded)
                            )}
                          >
                            {translateStatic(
                              "contact",
                              currentLocaleKey,
                              defaultLocaleKey
                            )}
                          </a>
                        </li>
                      );
                    }
                  })()}
                </ul>

                <div
                  className={`${theme3Styles.contactModal} ${
                    isShowModal ? `${theme3Styles.show}` : ` `
                  }`}
                >
                  <div className={`${theme3Styles.contactUs}`}>
                    <span
                      onClick={() => toggleModal(!isShowModal)}
                      className={theme3Styles.close}
                    >
                      <img alt="close" src={close} />
                    </span>
                    <h6>
                      {translateStatic(
                        "contact_us",
                        currentLocaleKey,
                        defaultLocaleKey
                      )}
                      <small>
                        {translateStatic(
                          "contact_form_text",
                          currentLocaleKey,
                          defaultLocaleKey
                        )}
                      </small>
                    </h6>
                    <ContactFormContainer />
                    {assigned_user && (
                      <div className={`${theme3Styles.contactAgent}`}>
                        <div className={theme3Styles.photo}>
                          <img
                            alt="phone"
                            src={
                              contactLogo ||
                              assigned_user.profile_image ||
                              profilePic
                            }
                          />
                        </div>
                        <div className={theme3Styles.details}>
                          <span className={theme3Styles.nm}>
                            {assigned_user.name}
                          </span>
                          <span className={theme3Styles.txt}>
                            <strong>
                              {translateStatic(
                                "email",
                                currentLocaleKey,
                                defaultLocaleKey
                              )}
                              :{" "}
                            </strong>
                            {assigned_user.email}
                          </span>
                          <span className={theme3Styles.txt}>
                            <strong>
                              {translateStatic(
                                "phone",
                                currentLocaleKey,
                                defaultLocaleKey
                              )}
                              :{" "}
                            </strong>
                            {(() => {
                              let phone_number = "";
                              phone_number += assigned_user.countryCode
                                ? assigned_user.countryCode + "-"
                                : "";
                              phone_number += assigned_user.mobile_phone
                                ? assigned_user.mobile_phone
                                : "";
                              return phone_number;
                            })()}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <a
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowModal(true)}
                >
                  Contact
                </a>
                <button type="button" onClick={() => setShowModal(true)}>
                  Contact
                </button>
                {showModal ? modalMarkup() : null}*/}
              </div>
            </nav>
          </div>
          {(() => {
            if (display_company_logo) {
              return (
                <Link
                  to={getTranslatedLink("/", currentLocaleKey)}
                  className={theme3Styles.logo}
                >
                  <h1>
                    <img alt="logo" src={showLogo} />
                  </h1>
                </Link>
              );
            }
          })()}
          <div className={theme3Styles.headerRight}>
            <div
              className={`${theme3Styles.headDropdown} ${
                isShowLang ? `${theme3Styles.open}` : ` `
              }`}
            >
              <div
                className={theme3Styles.txt}
                onClick={() => toggleLang(!isShowLang)}
              >
                <span className={theme3Styles.sel}>
                  <img
                    alt=""
                    src={require(`../images/flags/${
                      currentLocaleKey == "en" ? "us" : currentLocaleKey
                    }.svg`).default}
                  />
                  {currentLocale.name}
                </span>
                <span className={theme3Styles.arrow}>
                  <img alt="" src={dnarrow} />
                </span>
              </div>
              <ul>
                {locales.map(({ name, language_key }) => {
                  return (
                    <li key={language_key}>
                      <Link
                        to={getTranslatedLink(location.pathname, language_key)}
                      >
                        <img
                          alt=""
                          src={require(`../images/flags/${
                            language_key == "en" ? "us" : language_key
                          }.svg`).default}
                        />{" "}
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
