import React, { useContext } from "react";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { templateVariableValue } from "../../../helpers/templateVariables";
import { translateStatic } from "../../../helpers/translationHelper";
import * as theme4Styles from "../scss/theme4.module.scss";

import default_logo from "../images/logo.png";

function Footer() {
  const siteData = useContext(SiteMetaContext);
  const websiteName = siteData.remarso.domainByURL.website.name
    ? siteData.remarso.domainByURL.website.name
    : siteData.site.siteMetaData.title;
  const {
    remarso: {
      domainByURL: {
        website: {
          logo,
          templateConfiguration,
          template: { templateVariables },
          company: {
            address,
            city,
            country: { country_name },
            logo: company_logo,
            state,
            postal_code,
            email: company_email,
            website: company_website,
            phone: company_phone,
            socialProfiles,
          },
          contact_detail_user: user,
          setting: display_settings,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const {
    display_company_logo,
    display_contact_address,
    display_contact_phone,
    display_contact_website,
    display_contact_email,
  } = display_settings;

  const footerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-footer",
    locale,
    defaultLocale
  );
  const showLogo = footerLogo || logo || company_logo || default_logo;

  const footerText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "about-footer",
    locale,
    defaultLocale
  );

  const contact_email = user ? user.email : company_email;
  const contact_phone = user
    ? user.countryCode + user.mobile_phone
    : company_phone;
  const social_profiles = user ? user.socialProfiles : socialProfiles;

  return (
    <footer className={theme4Styles.mainFooter}>
      <div className={theme4Styles.wrapper}>
        {(() => {
          if (display_company_logo) {
            return (
              <div className={`${theme4Styles.fLogo}`}>
                <img alt="logo" src={showLogo} />
              </div>
            );
          }
        })()}
        <div className={`${theme4Styles.footerC}`}>
          <div className={theme4Styles.colm}>
            <div className={theme4Styles.footerTxt}>
              <p>{footerText}</p>
            </div>
          </div>
          <div className={theme4Styles.colm}>
            <div className={theme4Styles.footerInfos}>
              <div className={`${theme4Styles.footerInfoBox}`}>
                <h5>{translateStatic("contact_us", locale, defaultLocale)}:</h5>
                <div className={`${theme4Styles.contactInfo}`}>
                  {display_contact_email && contact_email && (
                    <span className={theme4Styles.txt}>
                      <strong>
                        {translateStatic("email", locale, defaultLocale)}:{" "}
                      </strong>
                      {contact_email}
                    </span>
                  )}
                  {display_contact_phone && contact_phone && (
                    <span className={theme4Styles.txt}>
                      <strong>
                        {translateStatic("phone", locale, defaultLocale)}:{" "}
                      </strong>
                      {contact_phone}
                    </span>
                  )}
                  {display_contact_website && !user && company_website && (
                    <span className={theme4Styles.txt}>
                      <strong>
                        {translateStatic("website", locale, defaultLocale)}:{" "}
                      </strong>
                      <a
                        href={company_website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company_website}
                      </a>
                    </span>
                  )}
                </div>
              </div>
              {display_contact_address && !user && (
                <div className={`${theme4Styles.footerInfoBox}`}>
                  <h5>{translateStatic("address", locale, defaultLocale)}:</h5>
                  <p>
                    {address},<br />
                    {city},{state}-{postal_code}
                    <br />
                    {country_name}
                  </p>
                </div>
              )}
              {social_profiles.length > 0 && (
                <div className={`${theme4Styles.footerInfoBox}`}>
                  <h5>
                    {translateStatic("follow_us", locale, defaultLocale)}:
                  </h5>
                  <div className={`${theme4Styles.fSocial}`}>
                    {social_profiles.map(({ type, profile }) => {
                      return (
                        <a
                          href={profile}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={type}
                        >
                          <img
                            alt={type}
                            src={require(`../images/social/${type}.png`).default}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`${theme4Styles.copyright}`}>
        <div className={theme4Styles.wrapper}>
          <div className={`${theme4Styles.copyrightC}`}>
            <div className={`${theme4Styles.copyrightSec}`}>
              &copy; {websiteName}. All rights resevered.
            </div>
            <div className={`${theme4Styles.copyrightSec}`}>
              Powered by{" "}
              <a
                href="https://realestatecrm.io/"
                target="_blank"
                rel="noreferrer"
              >
                RealEstateCRM.io
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
