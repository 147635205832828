import React from "react";
import PropTypes from "prop-types";
import IntlTelInput from "react-intl-tel-input";

import { translateStatic } from "../../../helpers/translationHelper";
import * as theme5Styles from "../scss/theme5.module.scss";

function ContactFormComponent5(props) {
  // console.log(props);
  const {
    data: { errors },
  } = props;
  // console.log(errors)
  // console.log(typeof props.data.ipDetails);

  return (
    <>
      {/* {(props.data.ipDetails) ? JSON.stringify(props.data.ipDetails) : ''} */}
      <span className={`text-green-500 text-xs`}>
        {props.data.api_success || ""}
      </span>
      <span className={`text-orange-500 text-xs`}>
        {props.data.api_error || ""}
      </span>
      <form onSubmit={props.handleSubmit}>
        <div className={`${theme5Styles.formRow}`}>
          <span className={`${theme5Styles.fieldTitle}`}>
            {translateStatic("first_name", props.locale, props.defaultLocale)}
          </span>
          <input
            type="text"
            className={theme5Styles.field}
            name="firstName"
            value={props.data.firstName}
            onChange={props.handleChange}
            onBlur={props.handleChange}
            placeholder={translateStatic(
              "first_name",
              props.locale,
              props.defaultLocale
            )}
          />
          {errors.firstName.length > 0 && (
            <span className={`text-red-500 text-xs`}>{errors.firstName}</span>
          )}
        </div>
        <div className={`${theme5Styles.formRow}`}>
          <span className={`${theme5Styles.fieldTitle}`}>
            {translateStatic("last_name", props.locale, props.defaultLocale)}
          </span>
          <input
            type="text"
            className={theme5Styles.field}
            name="lastName"
            value={props.data.lastName}
            onChange={props.handleChange}
            onBlur={props.handleChange}
            placeholder={translateStatic(
              "last_name",
              props.locale,
              props.defaultLocale
            )}
          />
          {errors.lastName.length > 0 && (
            <span className={`text-red-500 text-xs`}>{errors.lastName}</span>
          )}
        </div>
        <div className={`${theme5Styles.formRow}`}>
          <span className={`${theme5Styles.fieldTitle}`}>
            {translateStatic("phone_number", props.locale, props.defaultLocale)}
          </span>
          {/* <input
                        type="text"
                        className="field"
                        name="phoneNumber"
                        value={props.data.phoneNumber}
                        onChange={props.handleChange}
                        onBlur={props.handleChange}
                        placeholder="Phone Number"
                    /> */}
          <IntlTelInput
            fieldName="phoneNumber"
            containerClassName={`intl-tel-input`}
            inputClassName={theme5Styles.field}
            onPhoneNumberChange={(valid, number, country) =>
              props.handlePhoneChange(valid, number, country)
            }
            onPhoneNumberBlur={(valid, number, country) =>
              props.handlePhoneChange(valid, number, country)
            }
            placeholder={translateStatic(
              "phone_number",
              props.locale,
              props.defaultLocale
            )}
          />
          {errors.phoneNumber.length > 0 && (
            <span className={`text-red-500 text-xs`}>{errors.phoneNumber}</span>
          )}
        </div>
        <div className={`${theme5Styles.formRow}`}>
          <span className={`${theme5Styles.fieldTitle}`}>
            {translateStatic(
              "email_address",
              props.locale,
              props.defaultLocale
            )}
          </span>
          <input
            type="text"
            className={theme5Styles.field}
            name="emailAddress"
            value={props.data.emailAddress}
            onChange={props.handleChange}
            onBlur={props.handleChange}
            placeholder={translateStatic(
              "email_address",
              props.locale,
              props.defaultLocale
            )}
          />
          {errors.emailAddress.length > 0 && (
            <span className={`text-red-500 text-xs`}>
              {errors.emailAddress}
            </span>
          )}
        </div>
        <div className={`${theme5Styles.buttonset}`}>
          <button
            className={`${theme5Styles.btn} ${theme5Styles.btnLg} ${theme5Styles.btnPrimary} ${theme5Styles.btnBlock}`}
          >
            {translateStatic(
              "request_more_info",
              props.locale,
              props.defaultLocale
            )}
          </button>
        </div>
      </form>
    </>
  );
}
ContactFormComponent5.propTypes = {
  ipDetails: PropTypes.object,
  props: PropTypes.object,
  errors: PropTypes.object,
  handlePhoneChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
};
export default ContactFormComponent5;
