import React, { useContext } from "react";
// import { Link } from "gatsby"

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { templateVariableValue } from "../../../helpers/templateVariables";
// import { translateStatic } from "../../../helpers/translationHelper";
import * as theme5Styles from "../scss/theme5.module.scss";

import default_logo from "../images/logo.png";
// import mail from "../images/mail.png";
// import phone from "../images/phone.png";
// import website from "../images/www.png";

function Footer() {
  const siteData = useContext(SiteMetaContext);
  const websiteName = siteData.remarso.domainByURL.website.name
    ? siteData.remarso.domainByURL.website.name
    : siteData.site.siteMetaData.title;
  const {
    remarso: {
      domainByURL: {
        website: {
          logo,
          templateConfiguration,
          template: { templateVariables },
          company: {
            address,
            city,
            country: { country_name },
            logo: company_logo,
            state,
            postal_code,
            email: company_email,
            website: company_website,
            phone: company_phone,
          },
          contact_detail_user: user,
          setting: display_settings,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const {
    display_company_logo,
    display_contact_email,
    display_contact_phone,
    display_contact_website,
    display_contact_address,
  } = display_settings;

  const footerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-footer",
    locale,
    defaultLocale
  );

  const showLogo = footerLogo || logo || company_logo || default_logo;

  const footerText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "about-footer",
    locale,
    defaultLocale
  );

  const contact_email = user ? user.email : company_email;
  const contact_phone = user
    ? user.countryCode + user.mobile_phone
    : company_phone;

  return (
    <footer className={`${theme5Styles.mainFooter}`}>
      <div className={`${theme5Styles.wrapper}`}>
        <div className={`${theme5Styles.footerInfo}`}>
          {(() => {
            if (display_company_logo) {
              return (
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className={`${theme5Styles.footerLogo}`}
                >
                  <img
                    alt="logo"
                    className={theme5Styles.logo}
                    src={showLogo}
                  />
                </a>
              );
            }
          })()}
        </div>
        <div className={`${theme5Styles.footerRow}`}>
          <div className={`${theme5Styles.colm}`}>
            <p>{footerText}</p>
          </div>
          <div className={`${theme5Styles.colm}`}>
            <div className={theme5Styles.copyrightTxt}>
              &copy; {websiteName}.<br />
              All rights resevered.
            </div>
            <div className={theme5Styles.poweredBy}>
              Powered by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://realestatecrm.io/"
              >
                RealEstateCRM.io
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
