import _ from "lodash";

export const getTranslatedValue = (
  translations,
  key,
  locale,
  defaultLocale = "en"
) => {
  const translationObj =
    _.find(translations, { locale: locale }) ||
    _.find(translations, { locale: defaultLocale });
  if (translationObj) {
    return translationObj[key] || _.get(translations[0], key);
  } else {
    return _.get(translations[0], key);
  }
};

export const getTranslatedLink = (link, locale) => {
  if (link.charAt(0) == "/") {
    link = link.substr(1);
  }

  let arr = link.split("/");

  return "/" + link.replace(arr[0], locale);
};

export const getCurrentLocale = (link) => {
  if (link.charAt(0) == "/") {
    link = link.substr(1);
  }

  let arr = link.split("/");

  let locale = arr[0];

  if (parseInt(locale) == locale || locale.length > 2) {
    locale = "en";
  }

  return locale;
};

export const translateStatic = (key, locale, defaultLocale = "en") => {
  let translations = {};

  try {
    translations = require(`../translations/${locale}.json`);
  } catch (e) {
    try {
      translations = require(`../translations/${defaultLocale}.json`);
    } catch (e) {
      translations = require("../translations/en.json");
    }
  }

  return translations[key];
};
